h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}

h1 {
  font-size: var(--h1-font);
}

h2 {
  font-size: var(--h2-font);
}

h3 {
  font-size: var(--h3-font);
}

h4 {
  font-size: var(--h4-font);
}

h5 {
  font-size: var(--h5-font);
}

h6 {
  font-size: var(--h6-font);
}

p {
  font-size: var(--p-font);
  color: var(--color-grey);
}

a {
  font-size: var(--a-font);
}

body {
  font-family: 'Avenir LT Std';
  margin: 0 auto;
  background: var(--color-primary);
  color: var(--color-white);
  font-weight: 300;
  font-display: swap;
}

.banner {
  background-color: var(--color-primary);
  background: url(../assets/hero.webp);
  background-position: center center;
  background-repeat: no-repeat;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
  padding: 30px 0 100px;
  text-align: center;
  width: 100%;
  float: left;
  height: 100vh;
  position: relative;
}

header.scroll {
  background: var(--color-white);
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 5%);
  transition: all .25s ease-in-out;
}

.header {
  padding: 10px 0;
  position: fixed;
  top: 0;
  transition: top 0.3s ease-in-out;
  width: 100%;
  z-index: 1001;
}

.header .navbar-nav .nav-link {
  color: var(--color-primary);
  font-size: var(--a-font);
  padding-left: 70px;
}

.header .navbar-nav .nav-link:hover {
  color: var(--color-purple);
}

.banner-content {
  padding-top: 0;
  width: 100%;
  float: none;
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
}

.sticky-cta {
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
  padding-right: 40px;
  padding-left: 40px;
  visibility: hidden;
}

.sticky-cta .btn-right {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  padding: 0;
  line-height: 30px;
  text-align: center;
}

.sticky-cta .btn-right svg {
  transform: rotate(-90deg);
}

.banner h1 {
  font-size: var(--h1-font);
  font-weight: 500;
  color: black;
}

.banner .anim {
  display: flex;
  justify-content: center;
}

.banner ul {
  list-style: none;
  margin-bottom: 0;
  height: 60px;
  padding-left: 12px;
  overflow: hidden;
  line-height: 60px;
}

.banner ul li {
  font-size: var(--h1-font);
  font-weight: 500;
  color: var(--color-white);
  background: var(--color-purple);
  animation: slide 12s steps(4) infinite;
  top: 0;
  text-align: left;
  position: relative;
  display: flex;
}

@keyframes slide {
  100% {
    top: -240px;
  }
}

.banner ul li span {
  position: relative;
}

.banner ul li span::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-left: 3px solid var(--color-white);
  background: var(--color-purple);
  left: 0;
  animation: typing 3s steps(8) infinite;
}

@keyframes typing {
  100% {
    left: 100%;
    margin: 0 -35px 0 35px;
  }
}

.banner p {
  font-size: var(--h4-font);
  padding: 0 0 20px;
  color: black;
}

.btn {
  padding: 16px 32px;
  font-size: var(--a-font);
  border-radius: 2px;
  border: 0;
  line-height: normal;
}

.btn-primary {
  background: var(--color-purple);
}

.btn-secondary {
  background: var(--color-white);
  color: var(--color-purple);
}

.btn-primary:hover {
  background: var(--color-purple-2);
}

.features {
  padding: 120px 0;
  width: 100%;
  float: left;
}

.features h2 {
  padding: 0 0 15px;
}

.features .box {
  margin-top: 40px;
}

.features h4 {
  padding: 15px 0 10px;
}

.feature {
  background-color: transparent;
  width: 100%;
  height: 300px;
  perspective: 1000px;
  margin-top: 30px;
}

.feature-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature:hover .feature-inner {
  transform: rotateY(180deg);
}

.feature-front {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: 60px 20px;
  border-radius: 12px;
}

.feature-back {
  position: absolute;
  width: 90%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 12px;
  background: var(--color-white);
  transform: rotateY(180deg);
}

.feature-back img {
  width: 100%;
}

.feature-back p {
  color: var(--color-purple-2);
  padding: 15px 15px;
  font-size: 1.15rem;
  line-height: normal;
}

.sep {
  padding: 10px 0;
  border-bottom: 1px solid var(--color-seprator);
  float: left;
  width: 100%;
}

.stats {
  padding: 0 0 70px;
  float: left;
  width: 100%;
}

.stats p {
  color: var(--white);
}

.stats .battle {
  background: var(--color-purple) 0.80%;
  width: 297px;
  height: 297px;
  text-align: center;
  border-radius: 50%;
  margin-top: 80px;
  position: absolute;
  left: 90px;
}

.stats .cal {
  background: var(--color-purple-2) 0.75%;
  width: 491px;
  height: 491px;
  text-align: center;
  border-radius: 50%;
  margin-top: 80px;
  position: relative;
  opacity: 0.9;
}

.stats .mx-auto {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stats .kilo {
  background: var(--color-purple) 0.60%;
  width: 425px;
  height: 425px;
  text-align: center;
  border-radius: 50%;
  margin-top: 80px;
  position: absolute;
  left: -80px;
  opacity: 0.8;
}

.border {
  border: 1px solid var(--color-seprator) !important;
  transform: rotate(90deg);
  width: 250px;
  display: flex;
}

.stat {
  float: left;
  width: 100%;
  padding: 70px 0 20px;
  display: flex;
  align-items: center;
}

.stat .goals {
  position: relative;
  display: flex;
  align-items: center;
}

.stat .goals h5 {
  position: absolute;
  margin: 0 46px;
  color: var(--color-purple-2);
}

.stat .session {
  position: relative;
  display: flex;
  align-items: center;
}

.stat .session h5 {
  position: absolute;
  margin: 0 62px;
  color: var(--color-purple-2);
}

.stat p {
  font-size: var(--h4-font);
}

.stat .text {
  padding-left: 20px;
}

.stat p span {
  color: var(--color-purple-2);
}

.cases h2 {
  padding: 15px 0;
}

.cases span {
  font-size: 14px;
  background: var(--color-white);
  color: var(--color-purple-2);
  border-radius: 50px;
  padding: 8px 60px;
}

.cases .text {
  text-align: left;
}

.cases .text label {
  font-size: var(--p-font);
  color: var(--color-purple-2);
  text-decoration: none;
}

.cases .text label h3 {
  color: var(--color-white);
  padding: 10px 0;
}

.cases .text ul {
  padding-left: 20px;
  margin-top: 15px;
  color: var(--color-grey);
  list-style-image: url(../assets/check.svg);
  line-height: 36px;
}

.cases .text ul li {
  font-size: var(--p-font);
}

.cases {
  display: block;
}

.cases .case1 {
  padding: 80px 0;
}

.testimonial {
  padding: 80px 0;
  display: block;
}

.testimonial p {
  padding: 15px 0 0;
}

.testimonial .box p {
  padding: 0 0 25px;
  color: var(--color-white);
}

.testimonial .box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.testimonial .boxes {
  text-align: left;
  padding: 40px 30px;
  background: var(--color-primary-20);
  flex-direction: column;
  flex: 0 0 33.33%;
  margin: 25px 5px 5px 5px;
}

.testimonial .box span {
  color: var(--color-white);
  text-decoration: none;
}

.testimonial .box span span {
  color: var(--color-purple-2);
}

.client-names {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clients {
  background: var(--color-white);
  padding: 80px 0;
  color: var(--color-primary);
}

.form-control {
  padding: 14px 15px
}

/* footer */
footer {
  padding: 80px 0;
}

footer .store img {
  width: 110px;
}

footer .newsletter {
  padding: 65px 50px;
  background: var(--color-purple);
  color: var(--color-white);
  border-radius: 25px;
}

footer .newsletter p {
  color: var(--color-purple-10);
  margin-top: 10px;
}

footer .footer {
  padding: 60px 0 40px 0;
}

footer .footer p {
  font-size: 14px;
}

footer .footer a {
  color: var(--color-grey);
  text-decoration: none;
}

footer .footer a img {
  margin-top: 50px;
  margin-right: 10px;
}

footer .footer img {
  margin-bottom: 10px;
}

footer .footer ul {
  list-style: none;
}

footer .footer ul li {
  float: left;
  margin-left: 15px;
}

.footer .resources a {
  display: block;
  margin-bottom: 10px;
}

footer .store,
footer .resources button,
footer .social button {
  background: transparent;
  border: none;
}

footer .store {
  margin-right: 15px;
  margin-bottom: 10px;
}

footer .resources button {
  color: var(--color-grey);
  text-decoration: none;
  margin-bottom: 25px;
}

.btn-check:active+.btn-primary,
.btn-check:checked+.btn-primary,
.btn-primary.active,
.btn-primary:active,
.show>.btn-primary.dropdown-toggle {
  background: var(--color-purple-2);
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  background: var(--color-purple-2);
  box-shadow: 0 0 0 0.25rem rgb(107 82 174 / 50%);
  border: 0;
}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgb(107 82 174 / 50%);
}

.banner .btn-primary {
  transition: all .25s ease-in-out;
  z-index: 10;
}

.react-multiple-carousel__arrow {
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.3);
  top: 80px;
  z-index: 1000;
}

.btn-secondary:hover {
  background: var(--color-purple-2);
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
  background: var(--color-purple-2);
  border: 0;
  box-shadow: 0 0 0 0.25rem rgb(107 82 174 / 50%);
}

.btn-check:active+.btn-secondary,
.btn-check:checked+.btn-secondary,
.btn-secondary.active,
.btn-secondary:active,
.show>.btn-secondary.dropdown-toggle {
  background: var(--color-purple-2);
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgb(107 82 174 / 50%);
}

.navbar-toggler:focus {
  box-shadow: none;
}

.mobile-header {
  display: none;
}

.navbar-toggler {
  font-size: 18px;
}

.offcanvas-end {
  width: 100%;
  border: 0;
}

.offcanvas-header {
  padding: 0 20px;
}

.float-left {
  float: left;
  width: 100%;
}

.progress-bar-chart svg {
  height: 275px;
  width: 275px;
}

.Typewriter {
  margin-left: 10px;
  color: var(--color-purple);
  font-size: var(--h1-font);
  font-weight: 500;
  position: relative;
  top: -8px;
}

.social {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1399px) {
  .stats .cal {
    width: 430px;
    height: 430px;
  }

  .stats .kilo {
    width: 355px;
    height: 355px;
  }
}

@media (max-width: 1366px) {
  .banner-content {
    bottom: 100px;
  }

  .stats h6 {
    font-size: 5rem;
  }
}

@media (max-width: 1280px) {
  .stats h6 {
    font-size: 4.5rem;
  }
}

@media (max-width: 1200px) {
  .banner {
    background-position: center center;
  }

  .banner-content {
    padding-top: 800px;
  }

  .banner h1 {
    font-size: var(--h1-font);
    font-weight: 500;
  }

  .features h4 {
    font-size: 24px;
  }

  .stats h6 {
    font-size: 70px;
  }

  .stats .battle {
    width: 230px;
    height: 230px;
  }

  .stats .cal {
    width: 340px;
    height: 340px;
  }

  .stats .kilo {
    width: 300px;
    height: 300px;
  }

  .banner .btn-primary {
    right: 36%;
    left: 36%;
  }

  .stats {
    padding: 0 0 80px;
  }
}

@media (max-width: 1024px) {
  .stats .battle {
    left: 60px;
  }
}

@media (max-width: 991px) {
  .header .navbar-nav .nav-link {
    padding-left: 0;
    padding-bottom: 40px;
  }

  .banner {
    background-position: center center;
  }

  .banner-content {
    bottom: 100px;
  }

  .banner .anim {
    display: inline-block;
  }

  .banner ul li {
    font-size: var(--h1-font);
    text-align: center;
  }

  @keyframes slide {
    100% {
      top: -240px;
    }
  }

  .navbar-nav {
    text-align: center;
  }

  .banner ul {
    height: 60px;
    line-height: 60px;
    margin-top: 10px;
  }

  .banner ul li span {
    margin: auto;
  }

  .stats h6 {
    font-size: 56px;
  }

  .stats .battle {
    width: 230px;
    height: 230px;
    left: 0;
  }

  .stats .cal {
    width: 280px;
    height: 280px;
    left: -30px;
  }

  .stats .kilo {
    width: 230px;
    height: 230px;
    left: 0;
  }

  .testimonial .boxes {
    flex: 100%;
  }

  .testimonial .d-flex {
    flex-flow: row wrap;
  }

  .stat {
    display: block;
  }

  .stat h5 {
    font-size: var(--h5-font);
  }

  .border {
    transform: rotate(0deg);
    width: 100%;
    margin: 50px 0;
  }

  .cases .case1 {
    padding: 30px 0;
  }

  /* .testimonial p br {
    display: none;
  } */
  .features {
    padding: 60px 0;
  }

  /* .features br {
    display: none;
  } */
  .features h4 {
    font-size: 24px;
  }

  .features .box {
    margin-top: 0;
  }

  footer .newsletter {
    padding: 45px 20px;
  }

  footer .newsletter p {
    margin-bottom: 20px;
  }

  footer .footer ul {
    padding-left: 0;
  }

  footer .footer a img {
    margin-top: 0;
    margin-bottom: 15px;
  }

  .banner .btn-primary {
    bottom: 140px;
  }

  .desk-nav {
    display: none;
  }

  .mobile-header {
    display: block;
  }
}

@media (max-width: 800px) {
  .banner-content {
    bottom: 50px;
  }

  .banner h1 {
    font-size: var(--h1-font-lowres);
  }

  .banner p {
    font-size: var(--p-font-lowres);
  }

  .Typewriter {
    font-size: var(--h1-font-lowres);
  }
}

@media (max-width: 767px) {
  .banner {
    min-height: 100vh;
    background-position: center center;
  }

  .banner-content {
    padding-top: 240px;
  }

  /* .banner br {
    display: none;
  } */
  .banner p {
    font-size: var(--p-font);
    padding: 0 50px;
    margin-bottom: 20px;
  }

  .features {
    padding: 80px 0;
  }

  .features .box {
    margin-top: 40px;
  }

  .stats {
    padding: 0;
  }

  .stats .kilo,
  .stats .cal,
  .stats .battle {
    position: inherit;
  }

  .feature-front {
    padding: 30px 20px;
  }

  .stats .cal {
    width: 290px;
    height: 290px;
    margin-left: 10px;
    margin-top: 10px;
  }

  .stats .kilo {
    width: 270px;
    height: 270px;
    margin-top: 10px;
  }

  .features h4 {
    font-size: 24px;
  }

  .stat {
    display: block;
    text-align: center;
  }

  .stat .goals {
    display: block;
  }

  .stats .battle {
    width: 200px;
    height: 200px;
    padding: 50px 0;
  }

  .stat .text {
    padding-left: 0;
    padding-top: 20px;
  }

  .stats h6 {
    font-size: 62px;
  }

  .stat .goals h5 {
    top: 65px;
    left: 0;
    right: 0;
  }

  .stat .session {
    display: block;
    margin-top: 40px;
  }

  .stat .session h5 {
    top: 65px;
    left: 0;
    right: 0;
  }

  .border {
    display: flex;
    margin-top: 40px;
    transform: rotate(0deg);
    width: 100%;
  }

  .cases {
    float: left;
    width: 100%;
    padding: 80px 0;
  }

  .cases .case1 {
    padding: 40px 0;
  }

  footer .newsletter p {
    margin-bottom: 20px;
  }

  footer .newsletter {
    padding: 45px 20px;
    text-align: center;
  }

  footer .newsletter .btn-secondary {
    width: 100%;
    margin-top: 15px;
  }

  footer .footer {
    text-align: center;
  }

  footer .footer p {
    margin: 20px 0;
  }

  footer .footer ul {
    display: flex;
    justify-content: center;
    padding-left: 0;
  }

  footer .social {
    margin-top: 20px;
  }

  .stat h5 {
    font-size: 62px;
  }

  .banner .btn-primary {
    right: 23%;
    left: 23%;
    bottom: 50px;
  }
}

@media (max-width: 567px) {
  .banner {
    min-height: 100vh;
    background: url(../assets/hero-mobile.webp);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .banner h1,
  .Typewriter {
    font-size: 2.5rem;
  }

  .banner-content {
    bottom: 90px;
    width: 97%;
  }

.cases .text {
  padding-left: 3%;
  padding-right: 3%;
}

  .cases .text ul li {
    font-size: var(--p-font-mobile);
    line-height: 1.7rem;
  }

  h2 {
    font-size: var(--h2-font-mobile);
  }

  p {
    font-size: var(--p-font-mobile);
  }
}