.blog {
    background: var(--color-white);
    color: var(--color-primary);
}

.blog .blog-banner {
    display: flex;
    flex-direction: column;
    color: var(--color-white);
    background-attachment: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2) 67%, rgba(0, 0, 0, 0.7)), url(https://images.pexels.com/photos/1827234/pexels-photo-1827234.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2);
    background-position: center center;
    background-repeat: no-repeat;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    padding: 30px 0 100px;
    text-align: center;
    width: 100%;
    float: left;
    height: 100vh;
    position: relative;
}

.blog-hero-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    margin-top: auto;
}

.blog-hero-text h1 {
    color: var(--color-white);
    font-weight: 100;
    letter-spacing: 0.075em;
    padding-top: 1%;
    vertical-align: middle;
}

.blog-hero-text p {
    font-size: var(--p-font-mobile);
    color: var(--color-white);
    font-weight: 100;
    padding: 2vh 0;
}

.author {
    font-size: var(--a-font);
    margin-bottom: 10vh;
    font-weight: 500;
    display: flex;
    justify-content: center;
}

.author a {
    text-decoration: none;
    color: black;
}

.author a:hover {
    text-decoration: underline;
    color: blue;
}

.blog-head {
    color: var(--color-white);
    background: var(--color-primary);
    display: flex;
    height: 100vh;
    /* float: left; */
    width: 100%;
    /* position: relative; */
    justify-content: center;
    align-items: center;
    text-align: center;
}

.blog-head .text {
    /* position: absolute;
    left: 0;
    right: 0;
    bottom: 470px; */
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: 400;
}

.blog-head .text h4 {
    font-weight: 100;
}

.blog-content {
    float: left;
    width: 100%;
    padding: 10vh 10vw;
    background: var(--color-white);
}

.blog-content p {
    color: var(--color-primary);
    font-size: 1.4rem;
    line-height: 2.2rem;
}

@import url('https://fonts.cdnfonts.com/css/tanjiro');

.big {
    font-family: 'Tanjiro', Courier, monospace;
    font-size: 8rem;
    line-height: 3rem;
}

.blog-content h2 {
    text-align: center;
    margin: 60px 0px 20px;
}

.offcanvas-title {
    opacity: 0;
}

footer {
    float: left;
    width: 100%;
}

@media (max-width:1366px) {
    .blog-content {
        padding: 55px 10px;
    }

    .blog-head .text {
        bottom: 800px;
    }

    /* .banner-content {
        bottom: 100px;
    } */
}

@media (max-width: 1200px) {
    .blog-banner {
        background-position: center center;
    }

    .blog-banner h1 {
        font-size: var(--h1-font);
    }
}

@media (max-width:991px) {
    .blog-content {
        padding: 55px 10px;
    }

    .blog-banner {
        background-position: center center;
    }
}

@media (max-width: 800px) {

    .blog-banner h1 {
        font-size: var(--h1-font-lowres);
    }

    .blog-banner p {
        font-size: var(--p-font-lowres);
    }
}

@media (max-width:767px) {
    .blog-banner {
        min-height: 100vh;
        background-position: center center;
    }

    .blog-banner p {
        font-size: var(--p-font);
        padding: 0 2vw;
        margin-bottom: 20px;
    }

    .blog-content {
        padding: 55px 10px;
    }

    .blog-content h2 {
        font-size: 1.625rem;
        margin: 30px 0px 20px;
    }

    .blog-head .text {
        bottom: 350px;
    }
}

@media (max-width: 567px) {
    .blog-banner {
        min-height: 100vh;
        justify-items: flex-end;
    }

    .blog-banner h1 {
        font-size: var(--h1-font-mobile);
        padding: 2vh 1vw 0;
    }

    .blog-hero-text p {
        font-size: 0.8em;
        padding-top: 1vh;
    }

    .blog-content {
        padding: 10vh 5vw;
    }

    .blog-content p {
        font-size: var(--p-font-mobile);
        line-height: 1.7em;
    }

    .big {
        font-size: 6em;
    }
}

#backBtn {
    display: block;
    position: fixed;
    top: 3vw;
    left: 3vw;
    z-index: 11;
    border: 0px;
    line-height: 0rem;
    background: rgba(255, 255, 255, .3);
    border-radius: 30px;
    height: 40px;
    width: 40px;
    transition: all .15s;
}

#backBtn:hover {
    background: rgba(255, 255, 255, .8);
}