@import url("https://fonts.cdnfonts.com/css/avenir-lt-std");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Avenir LT Std", sans-serif;
  font-display: swap;
}
@font-face {
  font-family: "Avenir LT Std";
  font-style: normal;
  src: url("https://fonts.cdnfonts.com/css/avenir-lt-std");
  font-display: swap;
}
body,
html {
  font-family: "Avenir LT Std", sans-serif !important;
  font-display: swap;
}
