:root {
    --color-primary: #141414;
    --color-primary-20: #25282c;
    --color-white: #ffffff;
    --color-purple: #6b52ae;
    --color-purple-2: #7540b0;
    --color-purple-10: #e2e1ff;
    --color-grey: #9ba9b4;
    --color-seprator: #33363a;

    --h1-font: 3.125rem;
    --h2-font: 2.5rem;
    --h3-font: 2rem;
    --h4-font: 1.5rem;
    --h5-font: 3.563rem;
    --h6-font: 5.75rem;
    --p-font: 1.25rem;
    --a-font: 1rem;

    --h1-font-mobile: 2rem;
    --h2-font-mobile: 1.875rem;
    --h3-font-mobile: 1.6rem;
    --h4-font-mobile: 1.5rem;
    --h5-font-mobile: 34px;
    --h6-font-mobile: 62px;
    --p-font-mobile: 1.063rem;

    --h1-font-lowres: 1.875rem;
    --h2-font-lowres: 34px;
    --h3-font-lowres: 34px;
    --h4-font-lowres: 1.5rem;
    --h5-font-lowres: 34px;
    --h6-font-lowres: 62px;
    --p-font-lowres: 1.063rem;
}
