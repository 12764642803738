.policy-container {
  font-family: Avenir LT Std;
  font-display: swap;
  color: #6b52ae;
  background-color: white;
  margin: 5vw;
  padding: 5vw;
}

.policy-title p {
  font-weight: 1000;
  font-size: xx-large;
  color: #6b52ae;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.policy-header {
  font-weight: 700;
  color: #7540b0;
  display: flex;
  align-items: left;
  padding: 20px;
}

.policy-body {
  display: flex;
  color: black;
  align-items: left;
  padding-left: 20px;
  padding-right: 20px;
  font-size: small;
}

.policy-container li {
  list-style-type: circle;
  color: black;
  align-items: left;
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: small;
}
